import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import Features from '../Features';
import BlogRoll from '../BlogRoll';
import FullWidthImage from '../FullWidthImage';
import Link from '../Link';

export const HomePageTemplate = ({
  image,
  title,
  heading,
  subheading,
  description,
  intro,
  posts,
}) => {
  const heroImage = getImage(image) || image;
  const { t } = useTranslation();

  console.log(intro);

  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subheading} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {heading}
                      </h3>
                      <p>{description}</p>
                    </div>
                  </div>
                  <Features gridItems={intro} />
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {t('common.latest_stories')}
                    </h3>
                    <BlogRoll posts={posts} />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        {t('home.read_more')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

HomePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.array,
  posts: PropTypes.array,
};

export default HomePageTemplate;
